<template>
  <div class="exchangew">
    <div class="exchangew-but flex" @click="exchangeRecordButton()">
      <div>兑换记录</div>
      <img
        src="https://cdn.bubbleplan.cn/static/marketplace/Group10527.png"
        alt=""
        class="exchangew-but-img"
      />
    </div>
    <div class="exchangew-box flex">
      <div
        v-for="(item, index) in exchangeList"
        :key="index"
        class="exchangew-box-li flex items-center flex-between"
      >
        <div class="exchangew-box-li-left flex">
          <img :src="item.img" alt="" class="exchangew-box-li-left-img" />
          <div class="flex flex-direction flex-between">
            <div class="exchangew-box-li-left-title">{{ item.name }}</div>
            <div class="exchangew-box-li-left-name">
              {{ item.name }}{{ item.leftNum }}：{{ item.rightNum }}
            </div>
          </div>
        </div>
        <div
          class="exchangew-box-li-button"
          @click="exchangeButton(item, index)"
        >
          兑换
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
    >
      <div class="dialoExchange">
        <img
          src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
          alt=""
          class="dialoExchange-title-img"
          @click="(dialogVisible = !dialogVisible), (count = '')"
        />
        <div class="dialoExchange-title">
          <div class="dialoExchange-title-text">兑换</div>
        </div>
        <img class="dialoExchange-img" :src="exchangeData.img" />
        <div class="dialoExchange-input">
          <el-input v-model="count" placeholder="请输入积分数量"></el-input>
        </div>
        <div class="dialoExchange-border">
          <div class="flex flex-between dialoExchange-border-bu">
            <div class="dialoExchange-border-title">所需数量：</div>
            <div class="dialoExchange-border-num">
              {{
                exchangeIndex == 1
                  ? "芯片"
                  : exchangeIndex == 0
                  ? "芯片积分"
                  : exchangeIndex == 2
                  ? "晶体积分"
                  : "晶体"
              }}
            </div>
          </div>
          <div class="flex flex-between dialoExchange-border-bu">
            <div class="dialoExchange-border-title">服务费：</div>
            <div class="dialoExchange-border-num">{{ exchangeData.rate }}%</div>
          </div>
          <div class="flex flex-between dialoExchange-border-bu">
            <div class="dialoExchange-border-title">消耗数量：</div>
            <div class="dialoExchange-border-num">
              {{
                exchangeData.rate != 0
                  ? (
                      count * exchangeData.leftNum +
                      count * exchangeData.leftNum * exchangeData.rate
                    ).toFixed(0)
                  : (count * exchangeData.leftNum).toFixed(0)
              }}
            </div>
          </div>
        </div>
        <div class="dialoExchange-button" @click="doExchange()">确认兑换</div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="exchangeRecord"
      width="30%"
      :show-close="false"
    >
      <div class="dialoExchange exchangeRecordClass">
        <img
          src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
          alt=""
          class="dialoExchange-title-img"
          @click="exchangeRecord = false"
        />
        <div class="dialoExchange-title">
          <div class="dialoExchange-title-t"></div>
          <div class="dialoExchange-title-text">兑换记录</div>
        </div>
        <div class="exchangeRecordClass-box" @scroll="handleScroll">
          <div
            v-for="(item, index) in exchangeRecordList"
            :key="index"
            class="exchangeRecordClass-box-li flex items-center flex-between"
          >
            <div class="exchangeRecordClass-box-li-left">
              <div class="exchangeRecordClass-box-li-left-title">
                {{ item.reason }}
              </div>
              <div class="exchangeRecordClass-box-li-left-time">
                {{ item.createTime }}
              </div>
            </div>
            <div class="exchangeRecordClass-box-li-num">
              {{ item.type == 0 ? "+" : "-" }}{{ item.integration }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      exchangeRecord: false,
      count: "",
      exchangeList: [],
      exchangeData: {},
      exchangeIndex: null,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      hasNextPage: false,
      exchangeRecordList: [],
    };
  },
  mounted() {
    this.exchange();
    this.userNum();
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.exchangeLog("bottom");
        }
      }
    },

    exchangeRecordButton() {
      this.page.pageNum = 1;
      this.exchangeLog();
      this.exchangeRecord = true;
    },
    //兑换记录
    async exchangeLog(type) {
      const params = {
        ...this.page,
      };
      const { data, code } = await this.$ajax.exchangeLog(params);

      if (type == "bottom") {
        this.exchangeRecordList.push(...data.list);
      } else {
        this.exchangeRecordList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },

    //兑换
    async doExchange() {
      if (!this.count) {
        return;
      }
      let integer = /^(?:0|(?:-?[1-9]\d*))$/
      if (!integer.test(this.count)) {
        this.$message.error("请输入正确数量");
        return;
      }
      const params = {
        id: this.exchangeData.id,
        count: this.count,
      };
      const { data, code } = await this.$ajax.doExchange(params);
      if (code == 200) {
        this.count = "";
        this.dialogVisible = false;
        this.userNum();
      }
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
      }
    },
    exchangeButton(item, index) {
      this.exchangeData = item;
      this.exchangeIndex = index;
      this.dialogVisible = true;
    },
    //获取兑换列表
    async exchange() {
      const { data, code } = await this.$ajax.exchangeList({
        pageNum: 1,
        pageSize: 10,
      });
      this.exchangeList = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.exchangew::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.exchangew {
  padding: 38px 107px;
  padding-left: 59px;
  // height: 700px;
  :deep(.el-dialog__header) {
    padding: 0;
  }
  :deep(.el-dialog__body) {
    padding: 0;
  }
  :deep(.el-dialog) {
    background: transparent !important;
  }
  &-but:hover {
    cursor: pointer;
  }
  &-but {
    color: rgb(243, 236, 228);
    font-size: 20px;
    align-items: center;
    justify-content: flex-end;
    &-img {
      width: 25px;
      height: 25px;
      margin-left: 6px;
    }
  }
  &-box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &-box {
    margin-top: 101px;
    width: 907px;
    height: 534px;
    border-radius: 17.89px;
    background: #685c84;
    box-shadow: 0 2.75px 2.75px 0 rgba(0, 0, 0, 0.25) inset;
    flex-wrap: wrap;
    padding: 22px 21px;
    overflow: auto;
    &-li:hover {
      cursor: pointer;
    }
    &-li:nth-child(2n) {
      margin-right: 0;
    }
    &-li {
      width: 424px;
      height: 122px;
      background: rgba(243, 236, 228, 1);
      border-radius: 10.32px;
      margin-bottom: 34px;
      margin-right: 16px;
      padding: 0 17px;
      &-left {
        &-img {
          width: 84px;
          height: 68px;
          margin-right: 16px;
        }
        &-title {
          color: rgb(77, 50, 131);
          font-size: 19px;
          font-weight: Bold;
        }
        &-name {
          color: rgb(77, 50, 131);
          font-size: 17px;
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        width: 97px;
        height: 42px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector606.png")
          no-repeat;
        background-size: 100% 100%;
        color: rgb(243, 233, 208);
        text-shadow: 0 1.73px 0 rgba(0, 0, 0, 0.25);
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-align: center;
        font-size: 18px;
        line-height: 42px;
      }
    }
  }
  :deep(.el-dialog) {
    box-shadow: none !important;
  }
  .dialoExchange {
    width: 547px;
    height: 603px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/547-603.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 31px 42px;
    position: relative;
    &-title {
      width: 100%;
      height: 51px;
      border-radius: 10px;
      padding: 0 7px;
      &-t {
        width: 52.5px;
      }
      &-text {
        color: rgb(87, 67, 82);
        font-family: "TsangerYuMo";
        font-size: 28px;
        font-weight: bold;
        text-align: center;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 52.5px;
        height: 52px;
        position: absolute;
        top: -7px;
        right: -21px;
      }
    }
    &-img {
      width: 101px;
      height: 81px;
      margin: auto;
      margin-top: 68px;
    }
    &-input {
      margin: auto;
      margin-top: 11px;
      width: 255px;
      :deep(.el-input__inner) {
        height: 63px;
        border: 0px solid #6c7984;
        color: rgb(230, 217, 217);
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector560.png")
          no-repeat !important;
        background-size: 100% 100% !important;
        font-size: 22px;
        font-weight: Bold;
        text-align: center;
      }
      :deep(.el-input__inner)::placeholder {
        color: rgb(230, 217, 217);
        font-size: 22px;
        font-weight: Bold;
      }
    }
    &-border {
      margin-top: 34px;
      width: 450px;
      height: 172px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector795.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 16px 23px;
      &-title {
        color: rgb(230, 217, 217);
        font-size: 22px;
        font-weight: Bold;
      }
      &-num {
        color: rgb(230, 217, 217);
        font-size: 22px;
        font-weight: Bold;
      }
      &-bu {
        margin-bottom: 20px;
      }
      &-bu:nth-child(3) {
        margin-bottom: 5px;
      }
    }
    &-button:hover {
      cursor: pointer;
    }
    &-button {
      margin: auto;
      margin-top: 53px;
      width: 213px;
      height: 77px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector33606.png")
        no-repeat;
      background-size: 100% 100%;
      color: rgb(243, 233, 208);
      font-family: "TsangerYuMo";
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 73px;
    }
  }

  .exchangeRecordClass {
    height: 613px;
    &-box::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-box {
      width: 100%;
      height: 400px;
      overflow: auto;
      margin-top: 50px;

      &-li {
        height: 108px;
        padding: 25px 26.72px 25.56px 28.58px;
        background: rgba(243, 236, 228, 1);
        border-radius: 10.72px;
        padding: 25px;
        margin-bottom: 12px;
        &-left {
          &-title {
            color: rgb(77, 50, 131);
            font-size: 20px;
            font-weight: Bold;
          }
          &-time {
            margin-top: 11.85px;
            color: rgb(77, 50, 131);
            font-size: 18px;
          }
        }
        &-num {
          color: rgb(77, 50, 131);
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>